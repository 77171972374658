import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import "../css/components/_vitabot.scss";
import {metadata} from '../../config'
import {Helmet} from 'react-helmet'

const faqData = [
  {
    title: "What is Supplement Assistant?",
    desc: "Supplement Assistant is an AI-powered chatbot designed to provide personalized advice on vitamins and supplements.",
  },
  {
    title: "How does Supplement Assistant work?",
    desc: "Supplement Assistant uses information about your age, sex, lifestyle, nutrition, and other details to recommend the most suitable vitamins and supplements for you.",
  },
  {
    title: "Does Supplement Assistant replace professional medical advice?",
    desc: "No. Supplement Assistant provides general advice on vitamins and supplements based on publicly available data. It's intended to support, not replace, professional medical advice. Always consult with a healthcare professional before starting any new supplement regimen.",
  },
  {
    title: "Is my information secure with Supplement Assistant?",
    desc: "Absolutely. We adhere strictly to data privacy laws and ensure that your information is stored and processed securely.",
  },
  {
    title: "Does Supplement Assistant replace professional medical advice?",
    desc: "No. Supplement Assistant is designed to provide general advice on vitamins and supplements. Always consult with a healthcare professional before starting any new supplement regimen.",
  },
  {
    title: "Who is behind making Supplement Assistant?",
    desc: "Supplement Assistant was developed and is maintained by AgeGroup.",
  },
  {
    title: "What can Supplement Assistant do?",
    desc: (
      <>
        {" "}
        <span>
          Supplement Assistant can identify and recommend vitamins, minerals and
          supplements that may be suitable for your lifestyle and your wellness
          objectives based on the data you provide.
        </span>{" "}
        <br />
        <span>
          Supplement Assistant cannot provide a specific diagnosis or answer
          questions based on symptoms, nor can it provide information about
          things like drug or medication interactions, or guidance around any
          long-term health conditions that you may be living with.
        </span>
      </>
    ),
  },
  {
    title: "Can I really depend on you?",
    desc: (
      <>
        <span>
          Viabot is an AI-powered chatbot that will provide personalized
          guidance around the vitamins, minerals and supplements that may be
          suitable for your lifestyle and health objectives.
        </span>{" "}
        <br />
        <span>
          We hope you find our guidance useful and dependable; however, Viabot
          is not a medical professional and you should consult with your GP or
          another medical professional before changing your diet or taking any
          vitamins, minerals and supplements.
        </span>{" "}
      </>
    ),
  },
  {
    title: "What are your sources for this information?",
    desc: (
      <>
        <span> Viabot is not on social media.</span>{" "}
        <span>
          However, you can follow AgeGroup by searching for us on Facebook,
          Twitter, LinkedIn and Instagram, or by clicking the links at the
          bottom of this page.
        </span>{" "}
        <span>
          You can also sign up for our weekly newsletter to receive an update
          every Sunday containing newly published and popular content, and
          exclusive offers from our partners.
        </span>
      </>
    ),
  },
  {
    title: "Can I follow you on Linkedin?",
    desc: (
      <>
        <span>
          Yes; just search for AgeGroup on LinkedIn and hit the follow button.
        </span>
        <span>
          If you’re interested in seeing regular updates and content from Age
          Times, Health Times and Pension Times, please search for AgeGroup on
          Facebook, Twitter, and Instagram, or click the social media buttons at
          the bottom of this page.
        </span>
      </>
    ),
  },
  {
    title: "Where do I report feedback?",
    desc: (
      <>
        Please report any feedback on Viabot to{" "}
        <a href="mailto:Support@agegroup.io">Support@agegroup.io</a>{" "}
      </>
    ),
  },
  {
    title: "How do I contact support?",
    desc: (
      <>
        You can contact us via email at{" "}
        <a href="mailto:Support@agegroup.io">Support@agegroup.io</a> .
      </>
    ),
  },
];

const Vitabot = () => {
  const getButton = (displayMode) => {
    return (
      <button
        className={`text-white text-[18px] rounded-[200px] font-inter font-semibold border-2 border-solid ${displayMode}`}
        style={{
          background: "linear-gradient(83deg, #7E8BB5 16.24%, #B3C0EB 88.01%)",
        }}
        onClick={() => {
          window.parent.postMessage(
            { event: "open_supplement_bot", open: true },
            "*"
          );
        }}
      >
        Try-out chatbot
      </button>
    );
  };

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={`${metadata.url}/bot`}/>
      </Helmet>
      <div className="vitabot-main-wrapper">
        <div className="flex flex-col max-w-[1287px] mr-auto ml-auto">
          <div className="flex flex-wrap intro-wrapper">
            <div className="flex flex-col mt-auto mb-auto w-[40%] intro-left-part">
              <div className="flex flex-col leading-[65px] intro-text-wrapper">
                <span className="text-denim font-outfit font-semibold text-[40px] m-0 intro-text">
                  Introducing,
                </span>
                <span className="text-denim font-outfit font-semibold text-[70px] m-0 vitabot-text">
                  AI Supplement Assistant
                </span>
              </div>
              <p className="mt-6 text-[20px] mb-0 mr-0 ml-0 font-inter font-normal leading-[25px] vitabot-desc">
                Welcome to our AI supplement assistant, your personalised guide
                to the world of vitamins and supplements.
              </p>
            </div>
            <div className="h-[450px] flex pt-[40px] image-wrapper">
              <img
                src="/images/botlanding.png"
                height={410}
                width={457}
                style={{ aspectRatio: "5 / 4.5" }}
                className="h-auto w-auto max-w-full max-h-full m-auto object-contain"
              />
            </div>
          </div>
          <div className="flex pt-6 pb-6 right-part">
            <div className="open-chatbot-mobile-container">
              {getButton("open-chatbot-mobile-button")}
            </div>
            <div className="h-[500px] w-full image-wrapper">
              <img
                src="/images/vitaboddesign.png?format=webp"
                className="h-auto w-auto max-w-full max-h-full"
                height={500}
                width={327}
                style={{ aspectRatio: "4 / 6" }}
              />
              {/* <img src="/images/vitaboddesign.png" className="h-full" /> */}
            </div>
            <div className="flex flex-col justify-center gap-8">
              <p className="text-[20px] mb-0 mr-0 ml-0 font-inter font-normal leading-[25px] paragraph-text">
                Our AI supplement assistant offers tailored advice based on
                publicly available, scientifically-backed information to help
                you navigate your wellness journey and make smarter decisions
                around your health and well-being. Start chatting with our AI
                supplement assistant today to discover what supplements could
                fit your unique lifestyle and health goals.
              </p>
              <p className="text-[20px] mb-0 mr-0 ml-0 font-inter font-normal leading-[25px] paragraph-text">
                All the information you provide will be stored securely and will
                never be shared without your permission.
              </p>
              <p className="text-[20px] mb-0 mr-0 ml-0 font-inter font-normal leading-[25px] paragraph-text">
                Please note, our AI supplement assistant does not provide
                medical advice. Always consult with a healthcare professional
                before making any changes to your diet or starting any new
                supplement regimen.
              </p>
              <div className="open-chatbot-container">
                {getButton("open-chatbot-desktop-button")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 pb-8 max-w-[1287px] mr-auto ml-auto faq-wrapper">
        <h3 className="text-denim text-center font-outfit font-semibold text-[40px]">
          F.A.Q
        </h3>
        <div className="flex flex-col gap-5">
          {faqData.map((faq) => (
            <FAQComponent faq={faq} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Vitabot;

const FAQComponent = ({ faq }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className="w-full max-w-[1200px] self-center bg-[#7f8ab733] p-4 rounded-[40px] flex justify-between items-center select-none cursor-pointer"
        onClick={() => setOpen((pre) => !pre)}
      >
        <div className="font-inter font-semibold break-words ml-5">
          {faq.title}
        </div>
        <img
          src="/images/arrow.svg"
          className="h-[20px] w-[25px] cursor-pointer"
          style={{ rotate: open ? "180deg" : "0deg" }}
        />
      </div>
      {open && (
        <div
          className="w-full max-w-[1160px] self-center font-inter font-normal"
          style={{
            margin: "0px 30px 0px",
            padding: "5px 0",
            borderBottom: "1px solid black",
            transition: "all 1.5s ease-in",
          }}
        >
          {faq.desc}
        </div>
      )}
    </>
  );
};
